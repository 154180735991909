require('jquery');
global.jQuery = require('jquery');
global.$ = require('jquery');
var _ = require('underscore');
var svg = require('./desvg.js');
var bg = require('./bgswitcher.js');



/*
================================================================================
* javascript information
* file name  : main.js
================================================================================
*/
 //SVGを編集可能な形に
$(function(){
  deSVG('.svg_color', true);
});

$(function(){
  $('.bg_block').bgSwitcher({
      images: ['./common/images/mv01.jpg','./common/images/mv02.jpg'], // 切り替える背景画像を指定
  });
});

$(function(){
  $('.menu-trigger').on('click', function(){
      $(this).toggleClass('active');
      $("nav").toggleClass('active');
  });
});


$(function(){
  $(window).scroll(function(){
    var h = $(window).scrollTop();
    if( h > 100){
      $("header").addClass("small");
    }else{
      $("header").removeClass("small");
    }
  });
});

//画像保存禁止
